<template>
    <div id="services_top">
        <article class="services_bg" :class="[{active : startLightAnimation}]">
            <!-- <figure></figure>
            <figure></figure>
            <figure></figure>
            <figure></figure>
            <figure></figure>
            <figure></figure>
            <figure></figure> -->
            <video id="bg_video" class="pc_video" ref="bg_video" muted playsinline webkit-playsinline>
                <source src="@/assets/video/service-kv.mp4" type="video/mp4">
            </video>
            <video id="bg_video2" class="phone_video" ref="bg_video2" muted playsinline webkit-playsinline>
                <source src="@/assets/video/service_iphone.mp4" type="video/mp4">
            </video>
        </article>
        
        <div class="inner txt-white txt-center" :class="[{active : startLightAnimation}]">
            <h2 class="txt-bold" :class="{active : startLightAnimation}">
                KNOW-HOW & TECHNOLOGY
                <span></span>
            </h2>
            <p class="lh-l" style="max-width: 442px; margin: auto;">
                HOWGROUP怎漾集團統籌一切關於品牌的各種服務，提供全方位創意方案，為品牌開創極大化價值。
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServicesTop',
    data: function(){
        return {
            startLightAnimation: false
        }
    },
    mounted: function(){
        this.$refs.bg_video.load();
        setTimeout(() => {
            this.$refs.bg_video.play();
            this.startLightAnimation = true;
        }, 500);
        this.$refs.bg_video2.load();
        setTimeout(() => {
            this.$refs.bg_video2.play();
            this.startLightAnimation = true;
        }, 500);
    }
}
</script>

<style scoped lang="scss">
    #services_top{
        // background-image: url(~@/assets/img/service/service-banner-1.jpg);
        background-color: #000;
        background-size: cover;
        background-position: center;
        padding-bottom: 100vh;
        position: relative;
        .inner{
            width: 100%;
            position: absolute;
            top: 67%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%) scale(.8);
            z-index: 2;
            opacity: 0;
            transition-timing-function: cubic-bezier(1,.18,1,1);
            transition-duration: .2s;
            transition-delay: .4s;
            &.active{
                opacity: 1;
                transform: translateX(-50%) translateY(-50%) scale(1);
            }
            h2{
                margin: 30px 0;
                text-transform: uppercase;
                position: relative;
                span{
                    width: 100%;
                    height: 560%;
                    position: absolute;
                    display: block;
                    top: -170%;
                    left: 0;
                    background-image: url(~@/assets/img/service/light.png);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    opacity: 0;
                    transform: scale(.3);
                    transform-origin: center;
                    transition: 1;
                }
                &.active{
                    span{
                        animation: light .5s .8s both;
                    } 
                }
            }
        }
        .services_bg{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            figure{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-size: cover;
                background-position: center;
                opacity: 0;
                transition-duration: .5s;
                &:nth-child(1){
                    background-color: #000;
                }
                &:nth-child(2){ background-image: url('~@/assets/img/service/service-banner-2.png'); z-index: 6; }
                &:nth-child(3){ background-image: url('~@/assets/img/service/service-banner-3.png'); z-index: 5; }
                &:nth-child(4){ background-image: url('~@/assets/img/service/service-banner-4.png'); z-index: 4; }
                &:nth-child(5){ background-image: url('~@/assets/img/service/service-banner-5.png'); z-index: 3; }
                &:nth-child(6){ background-image: url('~@/assets/img/service/service-banner-6.png'); z-index: 2; }
                &:nth-child(7){ background-image: url('~@/assets/img/service/service-banner-7.png'); z-index: 1; }
            }
            video{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                object-fit: cover;
                &#bg_video2{
                    display: none;
                }
            }
            &.active{
                figure{
                    opacity: 1;
                    &:nth-child(1){transition-delay: 0s;}
                    &:nth-child(2){transition-delay: 0.2s;}
                    &:nth-child(3){transition-delay: 0.45s;}
                    &:nth-child(4){transition-delay: 0.7s;}
                    &:nth-child(5){transition-delay: 0.85s;}
                    &:nth-child(6){transition-delay: 1s;}
                    &:nth-child(7){transition-delay: 1.1s;}
                }
            }
        }
    }
    @keyframes light{
        0%{
            opacity: 0;
            transform: scale(.3);
        }
        50%{
            opacity: 1;
            transform: scale(1);
        }
        100%{
            opacity: 0;
            transform: scale(1);
        }
    }
@media screen and (max-width: 600px){
    #services_top{
        .services_bg{
            video{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                object-fit: cover;
                &#bg_video{
                    display: none;
                }
                &#bg_video2{
                    display: block;
                }
            }
        }
    }
}
</style>