<template>
    <div ref="brandingBlock">
        <div id="tr"></div>
        <div id="services_branding" v-bind:style="{backgroundColor : blockColor[blockIndex]}" :class="[{active : blockFixed}]">
            <svg xmlns="http://www.w3.org/2000/svg" id="bg_text01" width="1920" viewBox="0 -100 1920 150">
                <text x="0" y="0" v-bind:fill="blockBgTextColor[blockIndex]"  v-bind:stroke="blockBgTextColor[blockIndex]" letter-spacing="11" style="font-size:220px;">
                    {{blockTitle[blockIndex][0]}}
                </text>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" id="bg_text02" width="1920" viewBox="0 -200 1920 150">
                <text x="31%" y="0" v-bind:fill="blockBgTextColor[blockIndex]"  v-bind:stroke="blockBgTextColor[blockIndex]" letter-spacing="11" style="font-size:220px;">
                    {{blockTitle[blockIndex][1]}}
                </text>
            </svg>
            <div class="inner w1200">
                <div class="left txt-bold" v-bind:class="blockTextClass[blockIndex]">
                    <h2>
                        {{blockTitle[blockIndex][0]}}<br>
                        <span class="mtl">
                            {{blockTitle[blockIndex][1]}}
                        </span>
                    </h2>
                    <h5 class="zn-slogan mtl">
                        {{blockTitle[blockIndex][2]}}
                    </h5>
                </div>
                <div class="right" v-bind:class="blockTextClass[blockIndex]">
                    <p class="block_slogan">
                        {{blockTitle[blockIndex][3]}}
                    </p>
                    <ul class="service_list">
                        <li v-for="item in blockItem[blockIndex]" class="p">
                            {{item}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div id="services_branding2" v-bind:style="{backgroundColor : blockColor[1]}">
            <svg xmlns="http://www.w3.org/2000/svg" id="bg_text01" width="1920" viewBox="0 -100 1920 150">
                <text x="0" y="0"  v-bind:fill="blockBgTextColor[1]"  v-bind:stroke="blockBgTextColor[1]" letter-spacing="11" style="font-size:220px;">{{blockTitle[1][0]}}</text>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" id="bg_text02" width="1920" viewBox="0 -200 1920 150">
                <text x="50%" y="0"  v-bind:fill="blockBgTextColor[1]"  v-bind:stroke="blockBgTextColor[1]" letter-spacing="11" style="font-size:220px;">{{blockTitle[1][1]}}</text>
            </svg>
            <div class="inner w1200">
                <div class="left txt-bold" v-bind:class="blockTextClass[1]">
                    <h2>
                        {{blockTitle[1][0]}}<br>
                        <span class="mtl">
                            {{blockTitle[1][1]}}
                        </span>
                    </h2>
                    <h5 class="zn-slogan mtl">
                        {{blockTitle[1][2]}}
                    </h5>
                </div>
                <div class="right" v-bind:class="blockTextClass[1]">
                    <p class="block_slogan">
                        {{blockTitle[1][3]}}
                    </p>
                    <ul class="service_list">
                        <li v-for="item in blockItem[1]" class="p">
                            {{item}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div id="services_branding3" v-bind:style="{backgroundColor : blockColor[2]}">
            <svg xmlns="http://www.w3.org/2000/svg" id="bg_text01" width="1920" viewBox="0 -100 1920 150">
                <text x="0" y="0"  v-bind:fill="blockBgTextColor[2]"  v-bind:stroke="blockBgTextColor[2]" letter-spacing="11" style="font-size:220px;">{{blockTitle[2][0]}}</text>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" id="bg_text02" width="1920" viewBox="0 -200 1920 150">
                <text x="50%" y="0"  v-bind:fill="blockBgTextColor[2]"  v-bind:stroke="blockBgTextColor[2]" letter-spacing="11" style="font-size:220px;">{{blockTitle[2][1]}}</text>
            </svg>
            <div class="inner w1200">
                <div class="left txt-bold" v-bind:class="blockTextClass[2]">
                    <h2>
                        {{blockTitle[2][0]}}<br>
                        <span class="mtl">
                            {{blockTitle[2][1]}}
                        </span>
                    </h2>
                    <h5 class="zn-slogan mtl">
                        {{blockTitle[2][2]}}
                    </h5>
                </div>
                <div class="right" v-bind:class="blockTextClass[2]">
                    <p class="block_slogan">
                        {{blockTitle[2][3]}}
                    </p>
                    <ul class="service_list">
                        <li v-for="item in blockItem[2]" class="p">
                            {{item}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div id="services_branding4" v-bind:style="{backgroundColor : blockColor[3]}">
            <svg xmlns="http://www.w3.org/2000/svg" id="bg_text01" width="1920" viewBox="0 -100 1920 150">
                <text x="0" y="0"  v-bind:fill="blockBgTextColor[3]"  v-bind:stroke="blockBgTextColor[3]" letter-spacing="11" style="font-size:220px;">{{blockTitle[3][0]}}</text>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" id="bg_text02" width="1920" viewBox="0 -200 1920 150">
                <text x="67%" y="0"  v-bind:fill="blockBgTextColor[3]"  v-bind:stroke="blockBgTextColor[3]" letter-spacing="11" style="font-size:220px;">{{blockTitle[3][1]}}</text>
            </svg>
            <div class="inner w1200">
                <div class="left txt-bold" v-bind:class="blockTextClass[3]">
                    <h2>
                        {{blockTitle[3][0]}}<br>
                        <span class="mtl">
                            {{blockTitle[3][1]}}
                        </span>
                    </h2>
                    <h5 class="zn-slogan mtl">
                        {{blockTitle[3][2]}}
                    </h5>
                </div>
                <div class="right" v-bind:class="blockTextClass[3]">
                    <p class="block_slogan">
                        {{blockTitle[3][3]}}
                    </p>
                    <ul class="service_list">
                        <li v-for="item in blockItem[3]" class="p">
                            {{item}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import { TweenMax } from 'gsap'

export default {
    name: 'ServicesBranding',
    data: function(){
        return {
            scrollDistence : window.scrollY,
            blockFixed : false,
            blockIndex : 0,
            blockTitle : [
                ['BRANDING', 'STRATEGY', '品牌策劃', '打從心底引發消費者共鳴, 創造和別人與眾不同的視覺張力。'],
                ['CREATIVE', 'DESIGN', '創意設計', '洞悉消費者心聲，為品牌提供最必需的全方位規劃。'],
                ['SOFTWARE', 'DESIGN', '軟體開發', '運用最新科技加乘，為品牌提供最適合的產品載具。'],
                ['PROMOTION', 'GIFT', '活動贈品規劃', '提供各種主題性行銷活動，最符合品牌調性的禮贈品，讓客人備受禮遇。']
            ],
            blockItem : [
                ['策略擬定', '創意發想', '品牌規劃', '數位活動策劃與建置', '數位行銷', '社群口碑經營', '影片製作', '實體活動策劃', '消費者互動', '數據分析'],
                ['CIS Planning', 'Visual & Graphic Design', 'Advertisement', 'Website', 'APP', 'Campaign Site', 'Event Site', 'Cinemagraphs', 'Motion Graphics'],
                ['系統整合開發', '電商系統開發', '預約租賃系統開發', '論壇系統開發', '影音串流', 'H5設計製作', '小程序開發', 'API串接', 'SDK串接', '行銷追蹤碼串接'],
                ['肖像合作洽談', '品牌贈品設計規劃(客製化)']
            ], 
            blockTextClass : ['', 'black', '', ''],
            blockColor : ['#E62234', '#FFC000', '#00D1A9', '#0067FF'],
            blockBgTextColor : ['#001D31', '#fff', '#0067FF', '#FFC000']
        }
    },
    methods: {
        scrollBlock: function(){
            var blockTopDistence = this.$refs.brandingBlock.offsetHeight;
            this.scrollDistence = window.scrollY;

            // console.log(blockTopDistence, this.scrollDistence);
            if(this.scrollDistence >= blockTopDistence){
                window.removeEventListener('scroll', this.scrollBlock, true);
                setTimeout(() => {
                    window.addEventListener('scroll', this.scrollBlock, true);
                }, 3000);
            }else{

            }
        }
    },
    mounted: function(){
        // window.addEventListener('scroll', this.scrollBlock, true);

        // const scene20 = this.$scrollmagic.scene({
        //     // ID of element where animation starts
        //     triggerElement: '#tr',
        //     triggerHook: 0.5,
        //     duration: 3000
        // })
        // .setTween('#services_branding', { 
        //     css: {
        //         backgroundColor: 'blue',
        //     }
        // })
        // .addIndicators({ name: 'scene' })

        // this.$scrollmagic.addScene(scene20)
        // this.$scrollmagic.attachTo(this.$refs.scrollBox)
    },
    destroyed: function(){
        // window.removeEventListener('scroll', this.scrollBlock, true);
    }
}
</script>

<style scoped lang="scss">
#services_branding,
#services_branding2,
#services_branding3,
#services_branding4{
    background-color: $color-red;
    padding-bottom: 100vh;
    position: relative;
    &.active{
        position: fixed;
        top: 0;
        left: 0;
    }
    .mtl{margin-left: 50px;}
    .inner{
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
    .left{
        width: 45%;
        position: relative;
        color: #fff;
        &::before{
            content: '';
            width: 40px;
            height: 5px;
            display: block;
            position: absolute;
            left: 0;
            top: -30px;
            background-color: #fff;
        }
        &.black{
            color: #000;
            &::before{
                background-color: #000;
            }
        }
    }
    .right{
        width: 55%;
        color: #fff;
        &.black{ color: #000; }
    }
    .zn-slogan{
        margin-top: 30px;
        letter-spacing: 4.5px;
    }
    .block_slogan{
        min-height: 110px;
    }
    .service_list{
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;
        li{
            font-family: "Noto Sans", "Noto Sans TC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
            width: calc(33.3333% - 20px);
            margin-bottom: 7%;
            margin-left: 20px;
            list-style: disc;
            white-space: nowrap;
        }
    }
    #bg_text01,
    #bg_text02{
        width: 100%;
        position: absolute;
        left: 0;
        text{
            font-family: 'Gotham';
            font-weight: bold;
        }
    }
    #bg_text01{
        top: 0;
    }
    #bg_text02{
        bottom: 0;
    }
}
@media screen and (max-width: 1023px){
    #services_branding,
    #services_branding2,
    #services_branding3,
    #services_branding4{
        .mtl{margin-left: 0;}
        .inner{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
        .left{
            width: 100%;
            margin-bottom: 7%;
        }
        .right{
            width: 100%;
        }
        .block_slogan{
            min-height: auto;
        }
        .service_list{
            margin-top: 5%;
            li{
                margin: 2% 0 2% 20px;
            }
        }
        .zn-slogan{
            margin-top: 5%;
        }
    }
}
@media screen and (max-width: 600px){
    #services_branding,
    #services_branding2,
    #services_branding3,
    #services_branding4{
        .service_list{
            li{
                width: calc(50% - 20px);
                white-space: inherit;
            }
        }
    }

}
</style>