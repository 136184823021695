<template>
    <div class="page">
        <publicHeader :ifReverse="true"/>
        <ServicesTop/>
        <ServicesBranding/>
    </div>
</template>

<script>
import publicHeader from '@/components/public/Header.vue'
import ServicesTop from '@/components/page/services/ServicesTop.vue'
import ServicesBranding from '@/components/page/services/ServicesBranding.vue'
export default {
    name: 'Home',
    components: {
        publicHeader,
        ServicesTop,
        ServicesBranding
    }
}
</script>

<style scoped lang="scss">
    .page{
        position: relative;
    }
</style>